import axios from "axios";
import { Toast } from "vant";
import $router from "@/router";
const service = axios.create({
  timeout: 60000,
});

// let reqList = [];

let loading;
function startLoading() {
  loading = Toast.loading({
    message: "加载中...",
    forbidClick: true,
    duration: 0,
  });
}

function endLoading() {
  loading.clear();
}

/* request拦截器 */
service.interceptors.request.use(
  (config) => {
    let token = JSON.parse(sessionStorage.getItem("userInfoXpz"))?.access_token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    //自定义
    // config.headers.Authorization= 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxNTQ0MTAzIiwidXNlcklkIjoiYThhNDZjNDktMDY1YS00ZDFjLWFlM2YtZjY1MTY0OTdhYmViIiwidXNlck5hbWUiOiLlvKDluKbnj60iLCJ4eGRtIjoiMTU0NCIsInR5cGUiOiIzIiwibW9iaWxlIjoiMTg2NjgwMDUwNjkiLCJ1c2VyY29kZSI6IjEwMyIsImV4cGlyZSI6MTY1Nzk1ODI5NjU4MX0.NsybFCMCcZoICkLB_GBpeA3xyCfN3tBKPvdGTqhgflFJuHSkFb5Ivs_ovCLYHvf4MsTD3FiDkrAoFU2HVfXK8adR00XodZ1HP96KdCPZM43H4cUaei0aTbnfCAQlcx09QUVbmVVb7LfUqRebm4IOHBn59SXdq_Krur7kaPtB3v0';
    // let cancel;
    // 设置cancelToken对象
    config.cancelToken = new axios.CancelToken(function () {});
    
    // 修改这里：增加对params中noLoading的判断
    if (!config.noLoading && (!config.params || !config.params.noLoading)) {
      startLoading();
    }
    
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* Response拦截器 */
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // 修改这里：增加对params中noLoading的判断
      if (!response.config.noLoading && (!response.config.params || !response.config.params.noLoading)) {
        endLoading();
      }
      return Promise.resolve(response.data);
    } else {
      Promise.reject(response.data);
    }
  },
  (error) => {
    let message = "";
    if (error && error.response) {
      switch (error.response.status) {
        // case 401:
        //   setTimeout(() => {
        //     Toast("请重新登录~")
        //     sessionStorage.clear();//清除缓存
        //     $router.push({path: '/login'});//返回登录页
        //   }, 2200);
        //   break;
        // case 404:
        //   setTimeout(() => {
        //     Toast("请重新登录~")
        //     sessionStorage.clear();//清除缓存
        //     $router.push({path: '/login'});//返回登录页
        //   }, 2200);
        //   break;
        // case 400:
        //   setTimeout(() => {
        //     Toast("请重新登录~")
        //     sessionStorage.clear();//清除缓存
        //     $router.push({path: '/login'});//返回登录页
        //   }, 2200);
        //   break;
        // }
        // return;

        case 302:
          message = "接口重定向了！";
          break;
        case 400:
          message = "参数不正确！";
          break;
        case 401:
          message = "您未登录，或者登录已经超时，请先登录！";
          break;
        case 403:
          message = "您没有权限操作！";
          break;
        case 404:
          message = `请求地址出错: ${error.response.config.url}`;
          break; // 在正确域名下
        case 408:
          message = "请求超时！";
          break;
        case 409:
          message = "系统已存在相同数据！";
          break;
        case 500:
          message = "服务器内部错误！";
          break;
        case 501:
          message = "服务未实现！";
          break;
        case 502:
          message = "网关错误！";
          break;
        case 503:
          message = "服务不可用！";
          break;
        case 504:
          message = "服务暂时无法访问，请稍后再试！";
          break;
        case 505:
          message = "HTTP版本不受支持！";
          break;
      }
    }
    Toast({
      message: message,
    });
    // 您未登录，或者登录已经超时，请先登录！
    if (error.response.status == 401) {
      let accountType = JSON.parse(sessionStorage.getItem("accountTypeXpz"));
      // 调用函数
      redirectToLogin(accountType);
    }
    if (error.message.includes("timeout")) message = "网络请求超时！";
    if (error.message.includes("Network"))
      message = window.navigator.onLine ? "服务端异常！" : "您断网了！";
    console.log(message);
    // $router.go(0);
    return Promise.reject(error);
  }
  // }
);

async function redirectToLogin(accountType) {
  try {
    // 使用 await 等待路由跳转完成
    await $router.push({ path: "/verifyLogin", query: { type: accountType } });

    // 执行同步操作（清除缓存）
    sessionStorage.clear();

    // 使用 await 等待路由重新加载完成
    await $router.go(0);
  } catch (error) {
    // 处理错误
    console.error(error);
  }
}

export default service;

export default [
  {
    path: "/billDetail", //账单明细
    name: "BillDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1,
    },
    component: () => import("@pages/shareOff/billDetail"),
  },
  // {
  //   path: "/waitingForShipmentOrder", //订单号-客户-待发货
  //   name: "WaitingForShipmentOrder",
  //   meta: {
  //     navBarFlag: false,
  //     tabBarFlag: false,
  //     requiredAuth: true,
  //     // deepth:0.5
  //   },
  //   component: () =>
  //     import("@pages/shareOff/billDetailTo/waitingForShipmentOrder"),
  // },
  {
    path: "/completedOrder", //订单号-客户-已完成
    name: "CompletedOrder",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      // deepth:0.5
    },
    component: () =>
      import("@pages/shareOff/billDetailTo/completedOrder"),
  },
  // {
    // path: "/waitingForReceiptFa", //发货号-商家-待收货
    // name: "WaitingForReceiptFa",
    // meta: {
    //   navBarFlag: false,
    //   tabBarFlag: false,
    //   requiredAuth: true,
    //   // deepth:0.5
    // },
  //   component: () =>
  //     import("@pages/shareOff/billDetailTo/waitingForReceiptFa"),
  // },
  {
    path: "/completedFa", //发货号-商家-已完成
    name: "CompletedFa",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      // deepth:0.5
    },
    component: () =>
      import("@pages/shareOff/billDetailTo/completedFa"),
  },
  {
    path: "/shareclient", //分享官我的-客户列表
    name: "Shareclient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      // deepth:0.5
    },
    component: () =>
      import("@pages/shareOff/clientAndOrder/client"),
  },
  {
    path: "/CombinationSharingGroup", //分享官-组合列表-分组
    name: "CombinationSharingGroup",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/shareOff/combinationSharing/group.vue"),
  },
  {
    path: "/CombinationSharingList", //分享官-组合列表-分组详情
    name: "CombinationSharingList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/shareOff/combinationSharing/list.vue"),
  },
  {
    path: "/combinationSharingListAllProduct", //分享官-组合列表-所有产品
    name: "CombinationSharingListAllProduct",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/shareOff/combinationSharing/allProductList.vue"),
  },
];
